.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
  min-width: 300px;
  min-height: 300px;
}

.App-header {
  background-image: url('./road_background.jpg');
  background-position: 50% 0%;
  background-size: cover;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  backdrop-filter: grayscale(100%) blur(5px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
  padding: 0px 20px;
}

@media screen and (min-width: 1440px){
  .App-header {
    background-position: 50% 20%;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.join {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.join-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
}

@media screen and (max-width: 1000px){
  .join {
    width: 100%;
  }
  .join-form {
    width: 70%;
  }
}

.join-form__input {
  border-radius: 4px;
  box-sizing: border-box;
  border: 2px solid transparent;
  display: block;
  font-size: .9rem;
  margin-bottom: 10px;
  padding: 6px 10px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.join-form__input:hover {
  border: 2px solid lightskyblue;
}

.join-form__input:focus-visible {
  outline: 2px solid lightskyblue;
}

.join-form__submit {
  background: #1c2e4a;
  border: 1px solid #1c2e4a;
  letter-spacing: .2rem;
  text-transform: uppercase;
  width: 100%;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin: 20px 0;
  padding: 16px 10px;
  transition: all .3s;
  min-height: 50px;
}

.join-form__submit:hover {
  background: #344462;
}